@font-face {
    font-family: FontAwesomePro;
    src: url('../webfonts/Font-Awesome-5-Pro-Regular-400.otf') format('opentype');
    font-weight: 300;
}

.form-control:focus {
border-color: inherit;
-webkit-box-shadow: none;
box-shadow: none;
}

*:focus {
    outline: none;
}

html, body {
    overflow-x: hidden;
}

body {
    font-family: $font-stack;
    color: $agib-blue;
    overflow-x: hidden !important;
}

.form-group, .wpcf7 .wpcf7-form p {
    margin-bottom: unset !important;
}

*:focus {
    outline: none;
}

textarea:focus, input:focus{
    outline: none;
}

.navbar {
    height: $nav-height;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    ul {
        li {
            a {
                @extend %navbar-links;
            }
        }
    }
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: none;
    border-right: none;
    border-bottom: 0;
    border-left: none;
}

.dropdown-menu {
    border: none !important;
    border-radius: 0px;
}

.dropdown-menu li {
    position: relative;
}

.dropdown-menu li a {
    margin-left: 50px;
    padding: 10px 0px;
    &:before {
        content: url("../img/agib_pijltje_oranje.svg");
        position: absolute;
        top: 10px;
        left: 18px;
        width: 22px;
        height: 10px;
        padding-left: 15px;
    }
    &:hover {
        background: none;
        color: $agib-orange !important;
    }
}

.orange-link {
    @extend %orange_link;
}

.contact-btn {
    @extend %button-orange;
    padding: 13px 25px !important;
    max-height: 43px;
    display: flex;
    align-items: center;
    padding-right: 0px !important;
    a.nav-link {
    color: #ffffff !important;
    padding-left: 20px !important;
    }
}

h2.agib-search {
    @extend %h2_section;
    color: $agib-blue;
}

.wpcf7 input[type=submit] {
    @extend %button-orange;
}

section.main-header {
    min-height: calc(100% - #{$nav-height});
    background: $agib-grey;
    padding-top: 108px;
    .agibSlider {
        position: relative;
        height: $carousel-height;
        overflow: hidden;

        .border-triangle {
            z-index: 2;
            margin-top: 37px;
            opacity: .95;
            height: auto;
            color: #ffffff;
            width: 486px;
            height: 532px;

            h2 {
                font-size: 41px;
                line-height: 47px;
                font-weight: bold;
            }

            h3 {
                font-size: 41px;
                line-height: 47px;
                font-weight: 200;
            }

            p {
                @extend %paragraph;
            }

            .btn-white {
                @extend %button-white;
            }

            .white-link {
                @extend %white_link;
            }
        }

        .agibSlider-item {
            height: 673px;

            img {
                min-height: 100%;
                object-fit: cover;
                height: $carousel-height;
            }
        }
    }

    .search-sec {
        margin-top: calc(#{$search-height} / 2 - #{$search-height}) !important;
        position: relative;
        z-index: 2;

        .search-row {
            height: $search-height;
            box-shadow: 0px 0px 30px #00000029;

            .search-slt {
                background: $agib-grey;
                height: 50px;
                border: none;
                border-radius: 0;

                &::-webkit-input-placeholder {
                    color: $agib-grey-dark !important;
                    font-style: italic;
                    animation:
                        typing 3.5s steps(40, end),
                        blink-caret .75s step-end infinite;
                }
            }

            .btn-search {
                @extend %button-orange;
            }
        }
    }

    .four-col {
        background: $agib-grey;
        position: relative;
        padding-top: 20px;
        img {
            height: 60px;
        }
        .col {
            border-right: 1px solid #DFDFE0;
            &:last-child {
                border-right: none !important;
            }
        }
        h2 {
            @extend %four-col-h2;
            font-size: 16px !important;
        }
    }
}

section.page-header {
    height: 100%;
    background: $agib-grey;
    padding-top: 108px;
    .agibSlider {
        position: relative;
        height: $carousel-height-page;

        .border-triangle {
            z-index: 2;
            margin-top: 57px;
            opacity: .95;
            height: auto;
            color: #ffffff;
            width: 486px;
            height: 532px;

            h2 {
                font-size: 41px;
                line-height: 1.5 !important;
                font-weight: bold;
                padding-bottom: 20px;
            }

            h3 {
                font-size: 41px;
                line-height: 47px;
                font-weight: 200;
            }

            p {
                @extend %paragraph;
            }

            .btn-white {
                @extend %button-white;
            }

            .white-link {
                @extend %white_link;
            }
        }

        .agibSlider-item {
            height: 536px;

            img {
                min-height: 100%;
                object-fit: cover;
                height: $carousel-height;
            }
        }
    }
}

select#agibFilter {
    background: none;
    border: none;
    color: $agib-orange;
    font-size: 16px;
    font-weight: bold;
}

section.news-referenties-banner {
    height: 100%;
    background: $agib-grey;
    padding-top: 108px;
    .agibSlider {
        position: relative;
        height: $carousel-height-news-referenties;
        margin-bottom: 58px;

        .border-triangle {
            z-index: 2;
            opacity: 1;
            height: auto;
            color: #ffffff;
            width: 486px;
            height: 532px;

            h2 {
                font-size: 25px;
                line-height: 33px;
                padding-bottom: 25px;
            }

            h3 {
                font-size: 41px;
                line-height: 47px;
                font-weight: 200;
            }

            p {
                @extend %paragraph;
            }

            .btn-white {
                @extend %button-white;
            }

            .white-link {
                @extend %white_link;
            }
        }

        .agibSlider-item {
            height: inherit;

            img {
                min-height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                width: 654px !important;
                position: absolute;
                right: 0;
            }
        }
    }
}

section.flex-news-references {
    padding-top: 58px;
    .container {
        .row {
            max-width: 770px;
        }
    }
    .owl-news-references {
        padding: 20px 0px;
        .owl-nav {
            display: none;
        }
        .owl-dots {
            position: relative;
            bottom: 30px;
            span {
                background: #ffffff;
            }
            &.active {
                span {
                    background: transparent !important;
                    border: 1px solid #fff;
                }
            }
        }
        .item {
            height: 465px;
            img {
                height: inherit;
                object-fit: cover;
            }
        }
    }
    .gradient-box {
        margin-top: 70px;
        margin-bottom: 97px;
        background: transparent linear-gradient(122deg, #EF7D00 0%, #EF9C00 100%) 0% 0% no-repeat padding-box;
        .btn-white {
            @extend %button-white;
        }

        .white-link {
            @extend %white_link;
        }
    }
}

.load-more {
    span.show-more {
        background: #fff;
        z-index: 2;
        position: relative;
        color: $agib-orange;
        cursor: pointer;
    }

    span.show-less {
        background: #fff;
        z-index: 2;
        position: relative;
        color: $agib-orange;
        display: none;
    }

    hr {
        position: absolute;
        background-color: $agib-orange;
        left: 0;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
    }
}

section.show-products {
    margin-top: 124px;
    margin-bottom: 73px;

    .card {
        background: #fff;
        border: none;
        box-shadow: 0px 0px 35px #00000029;
        margin-bottom: 30px;

        .card-header {
            background: #fff;
            border: none;
        }

        .card-body {
            height: 300px;

            h3 {
                font-size: 18px;
                line-height: 24px;
                font-weight: bold;
                padding-bottom: 20px !important;
                min-height: 70px !important;
            }

            p {
                @extend %paragraph;
            }

            .orange-link {
                @extend %orange_link;
            }
        }
    }
}

section.gerelateerde-producten {
    margin-top: unset !important;
    margin-bottom: unset !important;
    padding-top: 95px;
    padding-bottom: 217px;
    &.light-grey {
        background: $agib-grey;
    }
    h2 {
        @extend %h2_section;
    }
    h3{
        @extend %h3_section;
    }
}

section.multiple-cols {
    padding: 49px 0px !important;

    &.light_grey {
        background: $agib-grey;
        .border-col {
            border-right: 1px rgba(112, 112, 112, 0.17) solid;
            &:last-child {
                border: none;
            }
        }

    }

    &.white {
        background: #ffffff;
        .border-col {
            border-right: 1px #F6F6F8 solid;
            &:last-child {
                border: none;
            }
        }
    }

    h2 {
        @extend %h2_section;
    }

    h3 {
        font-size: 20px;
        line-height: 35px;
        color: $agib-blue;
        font-weight: bold;
    }

    span {
        font-weight: 16px;
        line-height: 22px;
        color: $agib-blue;
    }

    a.orange-link {
        @extend %orange_link;
    }
}

textarea.wpcf7-form-control.wpcf7-textarea.wpcf7-validates-as-required.form-control.input-lg {
    border: 0;
    border-radius: 0;
}

table.agib-table tbody tr td {
    padding: 5px 10px;
    vertical-align: top;
    &:first-child {
        font-weight: bold;
    }
}

input[type="text"].form-control,
input[type="email"].form-control
 {
    padding: 10px 15px 0px;
    font-size: 16px !important;
    background: transparent;
    z-index: 2;
    position: relative;
    border: none;
    border-radius: none;
    height: 50px;
}
.wpcf7-form-control-wrap {
    background: #fff;
    height: 100%;
    display: block;
}

label {
    transform: translateY(-100%);
    z-index: 1;
    font-size: 14px;
    font-weight: normal;
    color: #6C6C6C;
    line-height: 42px;
    position: absolute;
    top: 40px;
    padding: 0 17px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.form-group.active > label {
    transform: translateY(-125%);
    color: #6C6C6C;
    font-size: 12px;
    padding: 0 16px;
}


section.section-newsletter {
    background: $agib-grey;
    border-bottom: 4px solid $agib-orange;
    h2 {
        @extend %h2_section;
    }

    p {
        padding-top: 10px;
        @extend %paragraph;
    }
    input {
        border: 0px;
        border-radius: 0px !important;
        .wpcf7-not-valid {
            border: 1px solid red !important;
        }
    }
    .newsletter-img {
        min-width: 585px;
    }
    .cow {
        position: absolute;
        right: -50px;
        bottom: 0px;
    }
}

section.cards {
    background: $agib-grey;
    border-bottom: 5px solid $agib-orange;
    padding-top: 96px;
    h2 {
        @extend %h2_section;
    }

    span.sub {
        font-size: 25px;
        line-height: 35px;
    }
    .owl-cards {
        .card {
            border: none;
            box-shadow: 0px 0px 35px #00000029;

            .card-header {
                overflow: hidden !important;
                border-bottom: 0px !important;
                .agib-watermerk {
                    position: absolute;
                    z-index: 3;
                    top: -8px;
                    left: 50%;
                    transform: translate(-50%);
                }
                .card-img-right {
                    width: 100%;
                    height: auto;
                    background-size: cover !important;
                    background-position: center center !important;
                    position: relative;
                }

                .card-img-right:before {
                    content: "";
                    position: absolute;
                    bottom: -1px;
                    left: -1px;
                    width: 0;
                    height: 20px;
                    border-right: 130px solid transparent;
                    border-bottom: 30px solid white;
                }

                .card-img-right:after {
                    content: "";
                    position: absolute;
                    bottom: -1px;
                    right: -1px;
                    width: 0;
                    height: 20px;
                    border-left: 130px solid transparent;
                    border-bottom: 30px solid white;
                }
            }

            .card-body {
                h2 {
                    font-size: 18px;
                    line-height: 47px;
                    color: $agib-blue;
                    font-weight: bold;
                }

                span.card-text {
                    color: $agib-grey-dark;
                    @extend %paragraph;
                }

                a.orange-link {
                    @extend %orange_link;
                }
            }
        }
    }

    .btn-blue {
        @extend %button-blue;
    }
}

section.cards-references {
    padding-bottom: 164px;
    h2 {
        @extend %h2_section;
    }

    span.sub {
        font-size: 25px;
        line-height: 35px;
    }

    .card {
        border: none;
        box-shadow: 0px 0px 35px #00000029;

        .card-header {
            .card-img-right {
                width: 100%;
                height: auto;
                background-size: cover !important;
                background-position: center center !important;
                position: relative;
            }

            .card-img-right:before {
                content: "";
                position: absolute;
                bottom: -1px;
                left: -1px;
                width: 0;
                height: 20px;
                border-right: 130px solid transparent;
                border-bottom: 30px solid white;
            }

            .card-img-right:after {
                content: "";
                position: absolute;
                bottom: -1px;
                right: -1px;
                width: 0;
                height: 20px;
                border-left: 130px solid transparent;
                border-bottom: 30px solid white;
            }
        }

        .card-body {
            h2 {
                font-size: 18px;
                line-height: 47px;
                color: $agib-blue;
                font-weight: bold;
            }

            span.card-text {
                color: $agib-grey-dark;
                @extend %paragraph;
            }

            .orange-link {
                @extend %orange_link;
            }
            p {
                font-size: 25px;
                line-height: 33px;
                color: #233B62;
                font-weight: bold;
            }
        }
    }

    .btn-blue {
        @extend %button-blue;
    }
}

span.cat {
    color: $agib-orange;
}

.news-referenties-banner span.cat {
    color: $agib-blue;
    padding-bottom: 20px;
}

section.section-map {
    padding-top: 100px;
    padding-bottom: 107px;
    .map-details {
        margin-bottom: 28px;
        img {
            max-width: 135px;
            height: 144px;
            object-fit: cover;
            clip-path: polygon(50% 0%, 80% 0, 100% 0, 100% 86%, 79% 93%, 50% 100%, 18% 94%, 0 89%, 0% 35%, 0 0);
            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                right: -1px;
                width: 0;
                height: 20px;
                border-left: 185px solid transparent;
                border-bottom: 30px solid white;
            }
        }
        ul {
            li {
                list-style: none;
            }
        }
    }
    .triggerHover {
        cursor: pointer; 
        &:hover {
            background: $agib-orange;
            ul li {
                color: #ffffff;
                &.name {
                    color: #233B62;
                    font-weight: bold;
                }
            }
        }
        ul li {
            color: #6C6C6C;
            &.name {
                color: #233B62;
                font-weight: bold;
            }
        }
    }
}

.wpcf7 {
    max-width: unset !important;
    margin: 0 auto!important;
}

section.referenties {
    background: $agib-grey;
    padding-top: 129px;
    padding-bottom: 57px;

    h2 {
        @extend %h2_section;
    }

    span.sub {
        font-size: 25px;
        line-height: 35px;
    }

    .news-article {
         a {
             text-decoration: none !important;
         }
    }

    .card {
        border: none;
        box-shadow: 0px 0px 35px #00000029;
        max-width: 370px;
        margin-bottom: 30px;

        .card-header {
            .card-img-right {
                width: 100%;
                height: auto;
                background-size: cover !important;
                background-position: center center !important;
                position: relative;
            }

            .card-img-right:before {
                content: "";
                position: absolute;
                bottom: -1px;
                left: -1px;
                width: 0;
                height: 20px;
                border-right: 185px solid transparent;
                border-bottom: 30px solid white;
            }

            .card-img-right:after {
                content: "";
                position: absolute;
                bottom: -1px;
                right: -1px;
                width: 0;
                height: 20px;
                border-left: 185px solid transparent;
                border-bottom: 30px solid white;
            }
        }

        .card-body {
            h2 {
                font-size: 18px;
                line-height: 1.5;
                color: $agib-blue;
                font-weight: bold;
            }

            span.card-text {
                color: $agib-grey-dark;
                @extend %paragraph;
            }

            a.orange-link {
                @extend %orange_link;
            }
        }
    }

    .btn-orange {
        @extend %button-orange;
    }
}

section.product-banner {
    background: $agib-grey;
    min-height: 644px;
    padding-top: 108px;
    padding-bottom: 20px;
    h2 {
        @extend %h2_section;
        padding-bottom: 50px;
    }
    p {
        @extend %paragraph;
    }
    .btn-orange {
        @extend %button-orange;
    }
    .btn-download {
        @extend %button-download;
    }
    .owl-carousel .owl-stage-outer {
        position: relative;
        overflow: hidden;
        -webkit-transform: translate3d(0px, 0px, 0px);
        background: #fff;
        border: 1px solid $agib-orange;
    }
    .owl-nav {
        display: none;
    }
    .owl-dots {
        position: relative;
        bottom: 30px;
        .owl-dot {
            &:hover span {
                background: $agib-orange;
            }
            span {
                background: #ffffff;
                border: 1px solid $agib-orange;
                &:hover {
                    background: $agib-orange;
                }
            }
            &.active {
                span {
                    background: $agib-orange;
                    &:hover {
                        background: $agib-orange;
                    }
                }
            }
        }
    }
}

ol.breadcrumb {
    background: none;
    margin: unset;
    padding: unset;
    padding: 10px 0px;
    &.cat {
        li {
            padding-bottom: 20px;
            svg{
                color: #ffffff;
            }
            a {
            color: #233B62 !important;
            }
        }
    }
    li {
        padding-right: 10px;
        svg{
            color: $agib-orange;
        }
        a {
            color: #6C6C6C;
            padding: 0px 5px;
            font-size: 16px;
            line-height: 2;
            letter-spacing: 1.2;
        }
        &:last-child {
            svg {
                display: none;
            }
        }
    }
}

.product-banner ol.breadcrumb svg {
    color: $agib-orange !important;
}

section.faq {
    padding-bottom: 107px;
    &.light-grey {
        background: $agib-grey;
        .panel-title {
            a {
                background: #ffffff !important;
            }
        }
    }
    .faq-titel {
        margin-top: 86px;
        margin-bottom: 30px;

        h2 {
            @extend %h2_section;
        }

        h3 {
            @extend %h3_section;
        }
    }
    .panel-group {
        margin-bottom: 0;
    }

    .panel-group .panel {
        border-radius: 0;
        box-shadow: none;
    }

    .panel-group .panel .panel-heading {
        padding: 0;
    }

    .panel-group .panel .panel-heading h4 a {
        background: #F6F6F8;
        display: block;
        font-size: 15px;
        line-height: 20px;
        font-weight: bold;
        color: $agib-blue;
        padding: 15px;
        text-decoration: none;
        transition: 0.15s all ease-in-out;
    }

    .panel-group .panel .panel-heading h4 a:hover,
    .panel-group .panel .panel-heading h4 a:not(.collapsed) {
        /*background: #fff;*/
        transition: 0.15s all ease-in-out;
    }

    .panel-group .panel .panel-heading h4 a:not(.collapsed) i:before {
        content: "-";
        font-size: 30px;
        line-height: 10px;
    }

    .panel-group .panel .panel-heading h4 a i {
        font-size: 12px;
    }

    svg {
        color: $agib-orange !important;
    }

    .panel-group .panel .panel-body {
        padding-top: 0;
    }

    .panel-group .panel .panel-heading+.panel-collapse>.list-group,
    .panel-group .panel .panel-heading+.panel-collapse>.panel-body {
        border-top: none;
    }

    .panel-group .panel+.panel {
        border-top: none;
        margin-top: 0;
    }
    .btn-orange {
        @extend %button-orange;
    }
}

.product-article {
    .card-body {
      h3  {
        color: $agib-blue;
        }   
     color: #6C6C6C;
    }
}


section.product-categories {
    background: #ffffff;
    padding-top: 129px;
    padding-bottom: 57px;

    div.title-product {
        margin-bottom: 20px;

        h2 {
            @extend %h2_section;
        }

        h3 {
            @extend %h3_section;
        }
    }

    h2 {
        @extend %h2_section;
    }

    span.sub {
        font-size: 25px;
        line-height: 35px;
    }

    .card {
        border: none;
        box-shadow: 0px 0px 35px #00000029;
        max-width: 370px;
        margin-bottom: 30px;

        .card-header {
            .card-img-right {
                width: 100%;
                height: auto;
                background-size: cover !important;
                background-position: center center !important;
                position: relative;
            }

            .card-img-right:before {
                content: "";
                position: absolute;
                bottom: -1px;
                left: -1px;
                width: 0;
                height: 20px;
                border-right: 185px solid transparent;
                border-bottom: 30px solid white;
            }

            .card-img-right:after {
                content: "";
                position: absolute;
                bottom: -1px;
                right: -1px;
                width: 0;
                height: 20px;
                border-left: 185px solid transparent;
                border-bottom: 30px solid white;
            }
        }

        .card-body {
            h2 {
                font-size: 18px;
                line-height: 47px;
                color: $agib-blue;
                font-weight: bold;
            }

            span.card-text {
                color: $agib-grey-dark;
                @extend %paragraph;
            }

            a.orange-link {
                @extend %orange_link;
            }
        }
    }

    .btn-orange {
        @extend %button-orange;
    }
}

section.cards.featurd-products {
    border-bottom: none !important;
    .card {
        border: none;
        box-shadow: 0px 0px 35px #00000029;

        .card-header {
            .card-img-right {
                width: 100%;
                height: auto;
                background-size: cover;
                background-position: center center;
                position: relative;
            }

            .card-img-right:before {
                content: "";
                position: absolute;
                bottom: -1px;
                left: -1px;
                width: 0;
                height: 20px;
                border-right: 180px solid transparent !important;
                border-bottom: 30px solid white;
            }

            .card-img-right:after {
                content: "";
                position: absolute;
                bottom: -1px;
                right: -1px;
                width: 0;
                height: 20px;
                border-left: 180px solid transparent !important;
                border-bottom: 30px solid white;
            }
        }

        .card-body {
            h2 {
                font-size: 18px;
                line-height: 1.5;
                min-height: 55px;
                color: $agib-blue;
                font-weight: bold;
            }

            span.card-text {
                color: $agib-grey-dark;
                @extend %paragraph;
            }

            a.orange-link {
                @extend %orange_link;
            }
        }
    }
}

section.text {

    a.video-modal {
        &:after {
            font-family: FontAwesomePro;
            content: "\f144";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 100px;
            color: #fff;
        }
    }

    &.light-grey {
        background: $agib-grey;
    }

    .agib-img {
        background-size: cover;
        background-position: center center;
        width: auto;
        height: 440px;

        &:before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 50%;
            height: 30px;
            border-right: 280px solid transparent;
            border-bottom: 60px solid #fff;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -1px;
            right: -1px;
            width: 50%;
            height: 30px;
            border-left: 280px solid transparent;
            border-bottom: 60px solid #fff;
        }
    }

    &.light-grey {
        .agib-img {
            background-size: cover;
            background-position: center center;
            width: auto;
            height: 440px;
    
            &:before {
                content: "";
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 50%;
                height: 30px;
                border-right: 280px solid transparent;
                border-bottom: 40px solid $agib-grey;
            }
    
            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                right: -1px;
                width: 50%;
                height: 30px;
                border-left: 280px solid transparent;
                border-bottom: 60px solid $agib-grey;
            }
        }
    }

    .content-tekst {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @extend %h2_section;
            column-span: all;
        }

        p {
            @extend %paragraph;
        }

        a {
            @extend %orange_link;
        }
    }

    .content-tekst.two-col {
        column-count: 2;
        column-gap: 40px;

        h2 {
            padding-bottom: 30px;
        }

        a {
            @extend %orange_link;
            display: inline !important;
            font-weight: normal !important;
            text-decoration: underline !important;

            &:after {
                display: none !important;
            }
        }
    }
}

/* breadcrumbs uitsluiten */
.news-referenties-banner .breadcrumb, .page-template-team-tpl .breadcrumb,  .page-template-faq-tpl .breadcrumb, .page-template-referenties-tpl-php .breadcrumb, .referenties-template-default .breadcrumb, .page-template-news-tpl .breadcrumb {
    display: none;
}

.tax-products_category section.text {
    max-width: 100% !important;
}

section.quote-img-text {
    display: flex;
    margin-top: 99px;
    span.quote {
        font-family: 'Caveat', cursive;
        font-weight: 400;
        font-size: 43px;
        line-height: 41px;
        letter-spacing: 3.98px;
    }
    h2 {
        padding-bottom: 20px;
    }
    .btn-orange {
        @extend %button-orange;
    }
    .quote-img {
        position: relative;
        top: 16px;
    }
}

section.newsletter {
    background: $agib-blue;
    height: 108px;
    .wpcf7-response-output {
        display: none !important;
    }
    h3 {
        font-size: 20px;
        line-height: 26px;
        font-weight: bold;
        color: #ffffff;
        width: 165px;
    }

    input {
        background: #fff;
        border: none;
        height: 45px;
        width: 100%;
        outline: 0;
    }

    button {
        color: #ffffff;
        font-size: 30px;
        background: #EF7D22;
    }
}

footer {
    background: $footer-gradient;
    color: #ffffff;

    a {
        color: #ffffff;
        transition: .3s;
        text-decoration: none !important;
        line-height: 2;
        &:hover {
            color: $agib-grey-dark;
        }
    }

    h5 {
        font-size: 18px;
        line-height: 31px;
        letter-spacing: 1.35px;
        font-weight: bold;
    }

    ul.adres {
        width: 210px;

        li {
            float: left;
            height: auto;
            margin: 0 10px 20px 0;
            width: 100px;

            &:nth-child(even) {
                margin-right: 0;
            }
        }
    }

    .footer-svg {
        position: absolute;
        right: 50px;
        z-index: 2;
        width: 217px;
        height: 484px;
    }
    .footer-bottom {
        margin-top: 88px;
        background-color: #F6F6F8;
        height: 65px;
        color: #233B62;
        ul li a {
            color: #233B62;
        }
    }
}

.contactlist li{
    line-height: 2;
}

.search .wrapper {
    padding: 130px 0;
}

.search-btn{
    display: flex;
}

.search-btn::before {
    display: flex;
    content: "";
    background-image: url('../img/Agib_Search.svg');
    background-size: 14px 14px;
    height: 14px;
    width: 14px;
    position: relative;
    top: 12px;
}

#search-fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    
    -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;

    -webkit-transform: translate(0px, -100%) scale(0, 0);
	-moz-transform: translate(0px, -100%) scale(0, 0);
	-o-transform: translate(0px, -100%) scale(0, 0);
	-ms-transform: translate(0px, -100%) scale(0, 0);
	transform: translate(0px, -100%) scale(0, 0);
    
    opacity: 0;
}

#search-fullscreen.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
    -moz-transform: translate(0px, 0px) scale(1, 1);
	-o-transform: translate(0px, 0px) scale(1, 1);
	-ms-transform: translate(0px, 0px) scale(1, 1);
	transform: translate(0px, 0px) scale(1, 1); 
    opacity: 1;
    height: 100vh;
    background: #fff;
    z-index: 999;
}

#search-fullscreen input[type="search"] {
    width: 970px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $agib-orange;
    background: #ffffff;
    font-size: 25px;
    font-weight: 300;
    border: 0px;
    margin: auto;
    outline: none;
    &::placeholder {
        color: $agib-blue;
    }
    &:focus {
        outline: 0; 
    }
}

.btn, .wpcf7 input[type=submit] {
    height: 50px !important;
}

#search-fullscreen .close {
    position: fixed;
    top: 15px;
    left: 15px;
    color: $agib-orange;
	opacity: 1;
	padding: 10px 17px;
    font-size: 100px;
    font-weight: 300;
}

#search-fullscreen form {
    border-bottom: 1px solid $agib-orange;
}

#search-fullscreen .close-span {
    color: $agib-blue;
    font-size: 15px;
}

#search-fullscreen .btn-search {
    @extend %button-orange;
    margin-left: 50px;
    margin-bottom: 10px;
}

img.car {
    padding-top: 30px;
    position: relative;
    left: -300px;            
}

.wpcf7 {
    overflow: hidden;
}


.wpcf7-not-valid {
    border: 1px solid red !important;
}

.card {
    box-shadow: 0 0 3px #00000029 !important;
    border-radius: 0px !important;
}

@media (max-width: $breakpoint-tablet) {
    section.page-header .agibSlider .border-triangle {
        height: auto;
        margin-top: 0px !important;
    }
    div#wpcf7-f9-o1 {
        display: flex;
        justify-content: start !important;
        margin-right: auto !important;
        padding: unset !important;
        margin: unset !important;
        margin-top: 20px;
    }
    section.gerelateerde-producten {
        margin-top: unset!important;
        margin-bottom: unset!important;
        padding-top: 95px;
        padding-bottom: 30px;
    }
    section.seperator {
        display: none !important;
    }
    section.product-banner {
        background: #f6f6f8;
        min-height: 644px;
        padding-top: 30px;
        padding-bottom: 20px;
    }
    section.product-categories {
        background: #fff;
        padding-top: 30px;
        padding-bottom: 57px;
    }
    .card {
        border: none;
        box-shadow: 0px 0px 35px #00000029;

        .card-header {
            .agib-watermerk {
                position: absolute;
                z-index: 3;
                top: -8px;
                left: 50%;
                transform: translate(-50%);
            }
            .card-img-right {
                width: 100%;
                height: auto;
                background-size: cover !important;
                background-position: center center !important;
                position: relative;
            }

            .card-img-right:before {
                border: none !important;
                content: "";
            }

            .card-img-right:after {
                border: none !important;
                content: "";
            }
        }
    }
    .border-triangle {
        width: 100% !important;
        background-image: none !important;
        background: rgb(239,125,0) !important;
        background: linear-gradient(180deg, rgba(239,125,0,1) 35%, rgba(239,156,0,1) 100%) !important;    
        h2 {
            font-size: 31px !important;
            line-height: 1.5 !important;
            font-weight: 700;
            padding: 15px 0px !important;
        }
    }
    .navbar {
        bottom: 0 !important;
        top: unset;
        width: 100vw;
        overflow-x: hidden;
    }
    img.img-icon.ctc-analytics {
        display: none;
    }
    section.main-header, section.page-header {
        padding-top: 0px;
    }

    nav.navbar.navbar-expand-lg.navbar-dark.bg-white.openNav {
        top: 0;
        height: 100vh;
    }

    .openNav >  .container {
        position: absolute;
        top: 30px;
        padding: 0px 20px;
        ul {
            padding-top: 40px;
            li {
            padding: 15px 0px;
            &.contact-btn {
                margin-top: 50px;
            }
            }
        }
    }

    .navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(239,125,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
}

@media (max-width: $breakpoint-mobile) {
    section.flex-news-references .gradient-box .btn-white, section.main-header .agibSlider .border-triangle .btn-white, section.news-referenties-banner .agibSlider .border-triangle .btn-white, section.page-header .agibSlider .border-triangle .btn-white {
        background: #fff;
        font-size: 18px;
        line-height: 23px;
        border-radius: 25px;
        padding: 15px 20px;
        color: #ef7d00;
    }
    section.referenties {
        background: #f6f6f8;
        padding-top: 10px !important;
        padding-bottom: 57px;
    }
    .contactMob {
        .wpcf7-form-control-wrap {
            margin: unset !important;
        }
        label {
            transform: translateY(-100%);
            z-index: 1;
            font-size: 14px;
            font-weight: 400;
            color: #6c6c6c;
            line-height: 42px;
            position: absolute;
            top: 50px;
            padding: 0 17px;
            transition: all .2s ease;
        }
    }
    section.gerelateerde-producten {
        padding-bottom: 0px;
    }
    .centerMob {
        text-align: center;
    }
    section.seperator {
        display: none !important;
    }
    section.product-banner h2 {
        padding-bottom: 0px;
    }
    section.show-products {
        margin-top: 30px;
        margin-bottom: 73px;
    }
    section.product-categories {
        background: #fff;
        padding-top: 30px !important;
        padding-bottom: 57px;
    }
    section.news-referenties-banner {
        padding-top: 0px !important;
        .border-triangle h2 {
            font-size: 21px!important;
            line-height: 1.5!important;
            font-weight: 700;
            padding: 15px 0!important;
        }
    }
    section.product-banner {
        background: #f6f6f8;
        min-height: 644px;
        padding-top: 108px;
        padding-bottom: 20px;
        padding: 30px 20px;
        .btn-download {
            margin-bottom: 20px;
        }
    }
    ol.breadcrumb.cat li {
        padding-bottom: 0px;
    }
    section.flex-news-references {
        padding-top: unset !important;
    }
    section.text .content-tekst.two-col {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 1;
        -webkit-column-gap: 40px;
        -moz-column-gap: 40px;
        column-gap: 40px;
    }
    .wpcf7-form-control-wrap {
        background: #fff;
        height: 100%;
        display: block;
        margin: 10px 0px;
    }
    img.car {
        padding-top: 30px;
    }
    .agibSlider {
        height: unset !important;
    }
    .agibSlider .agibSlider-item img {
        position: unset !important;
        min-height: unset !important;
        -o-object-fit: cover;
        object-fit: cover;
        height: 150px !important;
    }
    .agibSlider .agibSlider-item {
        height: unset !important;
    }
    .border-triangle {
        position: unset !important;
        margin-top: unset !important;
        width: 100% !important;
        background-image: none !important;
        background: rgb(239,125,0) !important;
        background: linear-gradient(180deg, rgba(239,125,0,1) 35%, rgba(239,156,0,1) 100%) !important;    
        h2 {
            font-size: 31px !important;
            line-height: 1.5 !important;
            font-weight: 700;
            padding: 0 0!important;
            padding-top: 20px !important;
        }
    }
    .btn-search {
        width: 100px !important;
        padding: unset !important;
        padding: 13px 0px !important;
    }
    .navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(239,125,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
    section.quote-img-text {
        display: flex;
        margin-top: 40px;
    }
    section.text .agib-img {
        height: 240px;
    }
    section.section-newsletter {
        .wpcf7 {
            min-width: 100%;
        }
        .newsletter-img {
            min-width: unset !important;
        }
    }
    section.newsletter {
        background: #233b62!important;
        height: auto;
        overflow: hidden;
        label {
            top: 50px !important;
        }
        .ajax-loader {
            display: none;
        }
    }
    section.section-newsletter .cow {
        display: none;
    }
    footer ul.adres {
        width: 100%;
        li {
            width: 40%;
        }
    }
    .footer-svg {
        display: none;
    }
    .ht-ctc.style-3 svg {
        height: 30px !important;
        width: 30px !important;
    }
    .ht-ctc.ht-ctc-chat.style-3.mobile.ctc-analytics {
        position: absolute;
        bottom: 38px !important;
    }
    .navbar {
        bottom: 0 !important;
        top: unset;
        width: 100vw;
        overflow-x: hidden;
    }
    img.img-icon.ctc-analytics {
        display: none;
    }
    section.main-header, section.page-header {
        padding-top: 0px;
    }

    nav.navbar.navbar-expand-lg.navbar-dark.bg-white.openNav {
        top: 0;
        height: 100vh;
    }
    .navbar-toggler {
        margin-right: 10px;
        &:focus {
            outline: 0px !important;
        }
    }
    .openNav >  .container {
        position: absolute;
        top: 30px;
        padding: 0px 20px;
        ul {
            padding-top: 40px;
            li {
            padding: 5px 0px;
            &.search-btn {
                display: none;
            }
            &.contact-btn {
                margin-top: 20px;
                width: 50%;
            }
            }
        }
        button:focus {
            outline: 0px !important;
        }
        .navbar-toggler {
            margin-right: 10px;
            &:focus {
                outline: 0px !important;
            }
        }
    }
    .openNav {
        ul.dropdown-menu {
            padding-top: 0px !important;
            li a {
                margin-left: 20px !important;
                padding: 10px 0;
                &::before {
                    position: absolute;
                    /* top: 10px; */
                    left: -10px !important;
                    width: 22px !important;
                    top: 14px !important;
                    height: 10px;
                    padding-left: 15px;
                }
            }
        }
    }
    section.product-categories .card, section.referenties .card {
        max-width: unset !important;
    }
    select#agibFilter {
        margin: 40px 0px;
    }
}