// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from src/sass/bootstrap4/_variables.scss, paste it here and edit the value.

$font-stack: Helvetica, sans-serif;
$agib-orange: #EF7802; 
$agib-blue: #233B62 !important;
$agib-grey: #F6F6F8;
$agib-grey-dark: #6C6C6C;
$nav-height: 108px;
$search-height: 112px;
$btn-font-size: 18px;
$btn-line-height: 23px;
$btn-radius: 25px;
$btn-padding: 13px 39px;
$carousel-height: 60%;
$carousel-height-page: 100%;
$carousel-height-news-referenties: 479px;
// mobile vars 
$breakpoint-tablet: 1024px;
$breakpoint-mobile: 576px;

%navbar-links {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1.35px;
    color: $agib-blue;
    padding-right: 46px !important;
}

%paragraph {
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    font-weight: 200;
}

%four-col-h2 {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    width: 138px;
}

%button-orange {
    background: transparent linear-gradient(113deg, #EF7D00 0%, #EF9C00 100%) 0% 0% no-repeat padding-box !important;
    font-size: $btn-font-size !important;
    line-height: $btn-line-height !important;
    border-radius: $btn-radius !important;
    padding: $btn-padding;
    color: #ffffff !important;
    border: none !important;
    outline: 0 !important;
    height: auto !important;
    max-height: 50px;
}

%button-download {
    font-size: $btn-font-size !important;
    line-height: $btn-line-height !important;
    border-radius: $btn-radius !important;
    padding: $btn-padding !important;
    background: none; 
    border: 1px solid $agib-orange;
    color: $agib-orange;
}

%button-blue {
    background: #233B62;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    border-radius: $btn-radius;
    padding: $btn-padding;
    color: #ffffff;
}

%button-white {
    background: #ffffff;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    border-radius: $btn-radius;
    padding: $btn-padding;
    color: #EF7D00;
}

%white_link {
    font-size: 16px;
    line-height: 47px;
    letter-spacing: 1.2px;
    color:  #ffffff;
    display: block;
    text-decoration: none;
    font-weight: bold;
    &:after {
        font-family: FontAwesomePro;
        content: "\f105";
        padding-left: 10px;
    }
}

%orange_link {
    font-size: 16px !important;
    line-height: 47px !important;
    letter-spacing: 1.2px !important;
    color:  #EF7D00 !important;
    text-decoration: none;
    display: block;
    font-weight: bold!important;
    &:after {
        font-family: FontAwesomePro;
        content: "\f105";
        padding-left: 10px;
    }
}

%h2_section {
    font-weight: bold;
    color: $agib-blue;
    font-size: 25px;
    line-height: 35px;
}

%h3_section {
    font-weight: normal;
    color: $agib-blue;
    font-size: 25px;
    line-height: 35px;
}

/* The typing effect */
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
from, to { border-color: transparent }
50% { border-color: orange; }
}

/* footer */

$footer-gradient: transparent linear-gradient(225deg, #EF7D00 0%, #EF9C00 100%) 0% 0% no-repeat padding-box;